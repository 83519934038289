<template>
  <default-layout>
    <v-container id="promo-management-edit" fluid tag="section">
      <promo-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const PromoForm = () => import('@/components/promo-management/promo/form/promo-form.vue');

export default {
  name: 'promo-edit',
  components: {
    DefaultLayout,
    PromoForm,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({}),
  },
  async created() {
    await this.$store.dispatch('promo/form/restoreInitialState');
    this.$store.commit('promo/form/SET_FORM_TYPE', 'EDIT');
    this.$store.commit('promo/form/SET_PROMO_UUID', this.$route.params.uuid);
    this.$store.dispatch('promo/form/fetchBaseData');
  },
};
</script>
